export interface UniversalTag {
  id: string;
  name?: string;
  url?: string;
  config?: AdvertiserConfig;
}
export const isUniversalTag = (object: unknown): object is UniversalTag => {
  const tag = object as UniversalTag;
  return !!tag && tag.id !== undefined;
};

export interface AdvertiserConfig {
  enableAdvertiserConsentSignal?: boolean;
  enableAdvertiserLoyaltyOverride?: boolean;
}
