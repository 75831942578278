import { UniversalTag } from './index';
import isObject from '../isObject';

export type UpdateUniversalTag = Pick<UniversalTag, 'name' | 'url' | 'config'>;

export const isUpdateUniversalTag = (
  object: unknown
): object is UpdateUniversalTag => {
  const updateTag = object as UpdateUniversalTag;
  return (
    isObject(updateTag) &&
    (updateTag.name !== undefined ||
      updateTag.url !== undefined ||
      updateTag.config !== undefined)
  );
};
